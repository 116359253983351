var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EthereumProvider } from '@walletconnect/ethereum-provider';
function EthereumProviderInit(on_connected) {
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = window;
                    return [4 /*yield*/, EthereumProvider.init({
                            projectId: '837f024a5f370d092e56d88bb3f0edaa',
                            metadata: {
                                name: 'DressX Onchain',
                                description: 'DRESSX Fashion Marketplace',
                                url: 'https://onchain.dressx.com',
                                icons: ['https://onchain.dressx.com/static/assets/images/favicon_16x16.png']
                            },
                            showQrModal: true,
                            qrModalOptions: {
                                explorerRecommendedWalletIds: [
                                    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
                                    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
                                    '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369'
                                ],
                            },
                            optionalChains: [1, 137, 80002, 11155111],
                            rpcMap: {
                                80002: 'https://rpc-amoy.polygon.technology/'
                            },
                        })];
                case 1:
                    _a.ep = _b.sent();
                    console.log(["window.ep connected", window.ep.connected]);
                    console.log(["window.ep", window.ep]);
                    console.log(["window.ep session", window.ep.session]);
                    window.ep.on("connect", function (data) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            console.log(["ep connected", data]);
                            return [2 /*return*/];
                        });
                    }); });
                    window.ep.on('disconnect', function (data) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            console.log(["ep disconnected", data]);
                            return [2 /*return*/];
                        });
                    }); });
                    window.ep.on('accountsChanged', function (accounts) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            console.log(["ep accountsChanged", accounts]);
                            return [2 /*return*/];
                        });
                    }); });
                    window.ep.on('chainChanged', function (chainId) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            console.log(["ep chainChanged", chainId]);
                            return [2 /*return*/];
                        });
                    }); });
                    window.ep.on('session_event', function (data) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            console.log(["ep session_event", data]);
                            return [2 /*return*/];
                        });
                    }); });
                    window.ep.on('display_uri', function (data) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            console.log(["ep display_uri", data]);
                            return [2 /*return*/];
                        });
                    }); });
                    if (!(window.ep.connected == false)) return [3 /*break*/, 4];
                    console.log("window.ep connect...");
                    return [4 /*yield*/, window.ep.disconnect()];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, window.ep.connect()];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 4:
                    if (!!window.ep.session) return [3 /*break*/, 7];
                    console.log("window.ep.session connect...");
                    return [4 /*yield*/, window.ep.disconnect()];
                case 5:
                    _b.sent();
                    return [4 /*yield*/, window.ep.connect()];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7:
                    on_connected();
                    return [2 /*return*/];
            }
        });
    });
}
;
document.body.onload = function () {
    var wcConnectButton = document.getElementById("wcConnectButton");
    if (wcConnectButton) {
        wcConnectButton.onclick = function (event) {
            EthereumProviderInit(function () {
                var wc_connected = new Event('wc_connected');
                document.querySelector("body").dispatchEvent(wc_connected);
            });
        };
    }
};
document.querySelector("body").addEventListener("wc_login", function () {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            EthereumProviderInit(function () {
                var wc_logined = new Event('wc_logined');
                document.querySelector("body").dispatchEvent(wc_logined);
            });
            return [2 /*return*/];
        });
    });
});
document.querySelector("body").addEventListener("wc_connect", function () {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            EthereumProviderInit(function () {
                var wc_connected = new Event('wc_connected');
                document.querySelector("body").dispatchEvent(wc_connected);
            });
            return [2 /*return*/];
        });
    });
});
document.querySelector("body").addEventListener("wc_disconnect", function (event) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("wcDisconnectButton disconnect...");
                    if (!window.hasOwnProperty("ep")) return [3 /*break*/, 2];
                    return [4 /*yield*/, window.ep.disconnect()];
                case 1:
                    _a.sent();
                    event.detail.on_exec();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
});
